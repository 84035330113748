import React from 'react';
import { makeStyles } from '@material-ui/core';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import Loader from '../components/loader';
import { useParams } from 'react-router-dom';
import NotificationList from '../components/notifications/notificationList';

import { getNotifications } from '../api/requests';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const notificationTypes = {
  messages: 'NEW_MESSAGE',
  new_matches: 'NEW_MATCH',
  expiring_matches: 'EXPIRING_MATCH',
  mercury: '',
  mercury_active: 'MERCURY_RETROGRADE_ACTIVE',
  mercury_before: 'MERCURY_RETROGRADE_BEFORE',
  mercury_after: 'MERCURY_RETROGRADE_AFTER',
  soul_care: 'SOUL_CARE',
  daily_prospects: 'DAILY_PROSPECTS'
};

const prettifyNotificationType = (type) => {
  return type.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
};

function Notifications(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [updated, setUpdate] = React.useState(false);
  const params = useParams();
  
  const fetchNotifications = async (type) => {
    setLoading(true);
    try {
      const response = await getNotifications(type);
      if (response.data?.notifications) {
        setNotifications(response.data.notifications);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    ( async() => { await fetchNotifications(notificationTypes[params.type]); })()
  }, [params.type, updated]);
  
  React.useEffect(() => {
    ( async() => {
      if (updated) {
        await fetchNotifications(notificationTypes[params.type]);
      }
    })()
  }, [updated]);
  
  return (
    <div className={classes.root}>
      <TopBar title={`Notifications ${params.type ? `| ${prettifyNotificationType(params.type)}` : ''}`} />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { loading ? <Loader /> : null }
        <NotificationList notifications={notifications} onUpdate={setUpdate} />
      </main>
    </div>
  );
}

export default Notifications;
