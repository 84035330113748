import { Dialog, Button, TextField, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import React from 'react';
import { sendNotification } from '../../api/requests';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import momentUtils from '@date-io/moment';
import moment from 'moment';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;
const Hint = styled.div`
  font-size: .75rem;
  color: #cacaca;
  margin-bottom: 10px;
`;

export default function SendNotificationModal(props) {
  const params = useParams();

  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    title: '',
    body: '',
  });

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [includeDate, setIncludeDate] = React.useState(false);

  const handleTimeChange = (f) => {
    setSelectedDate(f);
    setIncludeDate(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIncludeDate(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      await sendNotification({
        title: values.title,
        body: values.body,
        ...(!isNil(params.id) ? { user: params.id } : {}),
        ...(includeDate ? { date: moment.utc(selectedDate.format('YYYY-MM-DDTHH:mm:ss')).toDate() } : {}),
      });
      setLoading(false);
      setValues({
        title: '',
        body: '',
      });
      props.onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  const onClose = () => {
    setIncludeDate(false);
    setSelectedDate(new Date());
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={onClose}>
      { loading ? <Loader /> : null }
      <Wrapper>
        {
          props.forAll
            ? <Hint>
                <span style={{ color: '#ff266c', marginRight: '4px'}}>*</span>
                This notification will be sent for all users
              </Hint>
            : null
        }
        <TextField label='Notification Title' onChange={handleChange('title')}/>
        <TextField label='Notification Body' multiline rows={4} onChange={handleChange('body')}/>
        <Hint style={{ marginTop: '30px', marginBottom: '0'}}>
          <span style={{ color: '#1f285a', marginRight: '4px'}}>*</span>
          Optional
        </Hint>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <Grid container justify="space-around" direction='column'>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time"
              value={selectedDate}
              onChange={handleTimeChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <ButtonWrapper>
          <Button variant='contained' color='primary' onClick={handleSend}>Send</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  )
};
