import { Dialog, Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import React from 'react';
import { uploadHoroscope } from '../../api/requests';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export default function UpdateHoroscopeModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [horoscope, setHoroscope] = React.useState({
    sign: '',
    description: '',
  });
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    if (props.horoscope?.sign && props.horoscope?.description) {
      setHoroscope({ ...props.horoscope });
    }
  }, [props.horoscope]);
  
  const updateDescription = (event) => {
    setHoroscope({
      ...horoscope,
      description: event.target.value
    });
  };
  
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };
  
  const handleUpdate = async () => {
    if (!horoscope.description && !file) return;
    
    const formData = new FormData();
    formData.append('sign', horoscope.sign);
    if (horoscope.description) {
      formData.append('description', horoscope.description);
    }
    if (file) {
      formData.append('file', file);
    }
    
    setLoading(true);
    try {
      await uploadHoroscope(formData);
      setLoading(false);
      setFile(null);
      props.onClose(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      { loading ? <Loader /> : null }
      <Wrapper>
        <div style={{ textTransform: 'capitalize', fontWeight: 600 }}>{horoscope.sign}</div>
        <TextField
          label='Description'
          onChange={updateDescription}
          placeholder={horoscope.description}
          value={horoscope.description}
        />
        <TextField
          type='file'
          style={{padding: '10px 0'}}
          onChange={handleFile}
        />
        
        <ButtonWrapper>
          <Button variant='contained' color='primary' onClick={handleUpdate}>Update</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  )
};
