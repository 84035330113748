import { Dialog, Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import React, {useEffect} from 'react';
import {changeZodiacDescription} from '../../api/requests';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export default function UpdateZodiacInterpretationModal({interpretation, zodiac, onClose, isOpen}) {
    const [loading, setLoading] = React.useState(false);
    const [zodiacDescription, setZodiacDescription] = React.useState(interpretation?.zodiacs?.[zodiac] || '');

    useEffect(() => setZodiacDescription(interpretation?.zodiacs?.[zodiac] || ''), [zodiac])

    const handleUpdate = async () => {
        setLoading(true);
        try {
            await changeZodiacDescription({ planet: interpretation.planet, zodiac, description: zodiacDescription});
            setLoading(false);
            onClose(true);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => onClose()}>
            { loading ? <Loader /> : null }
            <Wrapper>
                <div style={{ textTransform: 'capitalize', fontWeight: 600 }}>{interpretation.planet} on {zodiac}</div>
                <TextField
                    label='Zodiac Description'
                    onChange={(e) => setZodiacDescription(e.target.value)}
                    placeholder='Zodiac Description'
                    multiline
                    value={zodiacDescription}
                />

                <ButtonWrapper>
                    <Button variant='contained' color='primary' onClick={() => handleUpdate()}>Update</Button>
                </ButtonWrapper>
            </Wrapper>
        </Dialog>
    )
};
