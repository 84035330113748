import axios from 'axios';
import { isNil } from 'lodash';

const API = '/api/v1';

export const login = async (login, password) => {
  try {
    return axios.post(`${API}/admin/login`, {
      login,
      password
    });
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = async (phoneNumber) => {
  try {
    const url = phoneNumber ? `${API}/admin/users?query=${phoneNumber}` : `${API}/admin/users`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getUserById = async (userId) => {
  try {
    return axios.get(`${API}/admin/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getReports = async () => {
  try {
    return axios.get(`${API}/admin/reports`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserData = async ({ user_id, place_of_birth, date_of_birth, time_of_birth }) => {
  try {
    return axios.put(`${API}/admin/users/${user_id}`, {
      place_of_birth,
      date_of_birth,
      time_of_birth
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getNotifications = async (type) => {
  try {
    const url = type ? `${API}/notifications?category=${type}` : `${API}/notifications`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateNotification = async ({ id, title, body }) => {
  try {
    return axios.put(`${API}/notifications/${id}`, {
      title,
      body
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = async (id) => {
  try {
    return axios.delete(`${API}/notifications/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const createNotification = async ({ title, body, category }) => {
  try {
    return axios.post(`${API}/notifications`, {
      title,
      body,
      category
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendNotification = async ({ title, body, user = null, date = null }) => {
  try {
    return axios.post(`${API}/notifications/send`, {
      title,
      body,
      ...(!isNil(user) ? { user: user } : {}),
      ...(!isNil(date) ? { date: date } : {}),
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSubscription = async () => {
  try {
    return axios.get(`${API}/admin/subscriptions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const createSubscription = async ({ startDate, endDate }) => {
  try {
    return axios.post(`${API}/admin/subscriptions`, {
      startDate,
      endDate,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getHoroscopes = async () => {
  try {
    return axios.get(`${API}/admin/horoscopes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const uploadHoroscope = async (formData) => {
  try {
    return axios(
      {
        url: `${API}/admin/horoscopes`,
        method: 'POST',
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const cancelSubscription = async (userId = null) => {
  try {
    return axios.post(`${API}/admin/subscriptions/cancel`, {
      ...(!isNil(userId) ? { userId } : {})
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const createFreeSubForOne = async (userId, date) => {
  try {
    return axios.post(`${API}/admin/subscriptions/:${userId}`, {
      date
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getInterpretations = async ({planet}) => {
  try {
    return axios.get(`${API}/admin/natal-interpretations?planet=${planet}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
    );
  } catch (error) {
    console.log(error);
  }
};

export const changePlanetDescription = async ({planet, description}) => {
  try {
    return axios(
        {
          url: `${API}/admin/natal-interpretations/changePlanetDescription`,
          method: 'PUT',
          data: {planet, description},
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
    );
  } catch (error) {
    console.log(error);
  }
};

export const changeZodiacDescription = async ({planet, zodiac, description}) => {
  try {
    return axios(
        {
          url: `${API}/admin/natal-interpretations/changeZodiacDescription`,
          method: 'PUT',
          data: {planet, zodiac, description},
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
    );
  } catch (error) {
    console.log(error);
  }
};
