import React from 'react';
import styled from 'styled-components';
import TopBar from '../topBar';
import Sidebar from '../drawer';
import Loader from '../loader';
import { useParams } from 'react-router-dom';
import {
  makeStyles,
  Divider,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  GridList,
  GridListTile,
  Dialog,
  Button
} from '@material-ui/core';
import UpdateModal from '../modals/update';
import SendNotificationModal from '../modals/sendNotification';
import { cancelSubscription, getUserById } from '../../api/requests';

const UserDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px 0;
`;
const UserDataItem = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
  display: flex;
  align-items: center;
`;
const DataItemTitle = styled.span`
  margin-right: 10px;
  display: inline-block;
  width: 250px;
`;
const DataItemValue = styled.span`
  font-weight: 600;
  flex-grow: 1;
`;

const SectionTitle = styled.h2`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function User(props) {
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({
    user: null,
    chart: null,
    photos: [],
    archetypes: []
  });
  const [selectedPhoto, setSelectedPhoto] = React.useState(0);
  const [galleryOpened, setGalleryOpened] = React.useState(false);
  const [updateModalOpened, setUpdateModalOpened] = React.useState(false);
  const [sendNotificationOpened, setSendNotificationOpened] = React.useState(false);
  
  const classes = useStyles();
  const params = useParams();
  
  const fetchUser = async (userId) => {
    setLoading(true);
    
    try {
      const response = await getUserById(params.id);
      if (response.data?.user) {
        setUserData({ ...response.data });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  React.useEffect(() => { (async () => { await fetchUser(); })(); }, []);
  
  const handleOpenGallery = (imageIndex) => {
    if (!userData.photos) return;
    setSelectedPhoto(imageIndex);
    setGalleryOpened(true);
  };
  
  const handleCloseGallery = () => {
    setGalleryOpened(false);
  };
  
  const handleUpdateModalOpen = () => {
    setUpdateModalOpened(true);
  };
  
  const handleUpdateModalClose = async (valuesChanged) => {
    if (valuesChanged) {
      await fetchUser();
    }
    setUpdateModalOpened(false);
  };
  
  const cancelSub = async () => {
    setLoading(true);
    try {
      await cancelSubscription(userData.user._id);
      setLoading(false);
      await fetchUser();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  const hasActiveSub = () => {
    if (userData.user?.promoSubscriptionExpiresAt) {
      const now = new Date();
      const subUntil = new Date(userData.user.promoSubscriptionExpiresAt);
      return +now < +subUntil;
    } else {
      return false;
    }
  };
  
  const openSendNotification = () => setSendNotificationOpened(true);
  const closeSendNotification = () => setSendNotificationOpened(false);
  
  return (
    <>
      <div className={classes.root}>
        <TopBar title='Users' backButton />
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          { loading ? <Loader /> : null }
          <SectionTitle>
            User Data
            <Button color='primary' variant='contained' onClick={openSendNotification}>Send notification</Button>
          </SectionTitle>
          <Divider />
          <UserDataWrapper>
            <UserDataItem>
              <DataItemTitle>Name:</DataItemTitle>
              <DataItemValue>{userData.user?.firstName}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Email:</DataItemTitle>
              <DataItemValue>{userData.user?.email}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Last Active At:</DataItemTitle>
              <DataItemValue>{userData.user?.lastActivityAt ? new Date(userData.user.lastActivityAt).toLocaleString() : ''}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Phone:</DataItemTitle>
              <DataItemValue>{userData.user?.phoneNumber}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>City:</DataItemTitle>
              <DataItemValue>{userData.user?.location}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Date of birth:</DataItemTitle>
              <DataItemValue>{userData.user?.dob ? new Date(userData.user.dob).toUTCString() : ''}</DataItemValue>
              <Button variant='contained' color='primary' onClick={handleUpdateModalOpen}>Change</Button>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Time of birth:</DataItemTitle>
              <DataItemValue>{userData.user?.timeOfBirth}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Place of birth:</DataItemTitle>
              <DataItemValue>{userData.user?.placeOfBirthTitle}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Place of birth(latitude, longitude):</DataItemTitle>
              <DataItemValue>{userData.user?.placeOfBirth?.latitude}{userData.user?.placeOfBirth ? ',' : ''} {userData.user?.placeOfBirth?.longitude}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Gender:</DataItemTitle>
              <DataItemValue>{userData.user?.gender}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Orientation:</DataItemTitle>
              <DataItemValue>{userData.user?.orientation?.toString()}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Interested in:</DataItemTitle>
              <DataItemValue>{userData.user?.interestedIn}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Looking for:</DataItemTitle>
              <DataItemValue>{userData.user?.lookingFor}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Ethnicity:</DataItemTitle>
              <DataItemValue>{userData.user?.ethnicity}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Drinking:</DataItemTitle>
              <DataItemValue>{userData.user?.drinking}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Smoking:</DataItemTitle>
              <DataItemValue>{userData.user?.smoking}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Education:</DataItemTitle>
              <DataItemValue>{userData.user?.education}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Education place:</DataItemTitle>
              <DataItemValue>{userData.user?.educationPlace}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Free subscription until:</DataItemTitle>
              <DataItemValue>{userData.user?.promoSubscriptionExpiresAt ? new Date(userData.user.promoSubscriptionExpiresAt).toUTCString() : ''}</DataItemValue>
              {
                hasActiveSub()
                  ? <Button variant='contained' color='primary' onClick={cancelSub}>Cancel</Button>
                  : null
              }
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Height:</DataItemTitle>
              <DataItemValue>{userData.user?.height}</DataItemValue>
            </UserDataItem>
            <UserDataItem>
              <DataItemTitle>Profile Active:</DataItemTitle>
              <DataItemValue>{userData.user?.isActive?.toString() ?? true.toString()}</DataItemValue>
            </UserDataItem>
          </UserDataWrapper>
          
          <SectionTitle>Natal Chart</SectionTitle>
          <Divider />
          <UserDataWrapper>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Sign</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { userData.chart ?
                    Object.entries(userData.chart)
                      .filter(([key, value]) => key !== '_id' && key !== '__v' && key !== 'user')
                      .map(([key, value]) =>
                    <TableRow key={key} hover>
                      <TableCell>{key.replace('_', ' ')}</TableCell>
                      <TableCell>{value.zodiac}</TableCell>
                      <TableCell>{value.description}</TableCell>
                    </TableRow>
                    ) : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </UserDataWrapper>
          
          <SectionTitle>Archetypes</SectionTitle>
          <Divider />
          <UserDataWrapper>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  { userData.archetypes ?
                    userData.archetypes.map((value, index) =>
                      <TableRow key={index} hover>
                        <TableCell>{value.title}</TableCell>
                        <TableCell>{value.description}</TableCell>
                        <TableCell>{value._id === userData.user.archetype ? 'Selected' : ''}</TableCell>
                      </TableRow>
                    ) : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </UserDataWrapper>
          
          <SectionTitle>Photos</SectionTitle>
          <UserDataWrapper>
            <GridList cols={4}>
              { userData.photos.length ?
                userData.photos.map((photo, key) => (
                  <GridListTile key={photo._id} cols={1} onClick={() => handleOpenGallery(key)} style={{ cursor: 'pointer', userSelect: 'none' }}>
                    <img src={photo.path} alt=''/>
                  </GridListTile>
                )) : null
              }
            </GridList>
          </UserDataWrapper>
          <Divider />
          <Dialog open={galleryOpened} onClose={handleCloseGallery}>
            { userData.photos.length ?
              <img src={userData.photos[selectedPhoto].path} alt=""/>
              : null
            }
          </Dialog>
          <UpdateModal
            open={updateModalOpened}
            onClose={handleUpdateModalClose}
            dob={userData.user?.dob || null}
          />
          <SendNotificationModal
            open={sendNotificationOpened}
            onClose={closeSendNotification}
          />
        </main>
      </div>
    </>
  )
}