import 'fontsource-roboto';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ProtectedRoute from './components/protectedRoute';
import './App.css';
import AuthContainer from './containers/auth';
import Users from './containers/users';
import User from './components/users/userSingle';
import Reports from './containers/reports';
import Notifications from './containers/notifications';
import Notification from './components/notifications/notificationSingle';
import Subscriptions from './containers/subscriptions';
import Horoscopes from './containers/horoscopes';
import Interpretations from "./containers/interpretations";

function App() {
  return (
    <Router basename='/admin'>
      <Route path='/login' exact component={AuthContainer} />
      <Route path='/' exact component={AuthContainer} />
      <ProtectedRoute exact path='/users' render={() => <Users />} />
      <ProtectedRoute exact path='/reports' render={() => <Reports />} />
      <ProtectedRoute exact path='/users/:id' render={() => <User />} />
      <ProtectedRoute exact path='/notifications/:type' render={() => <Notifications />} />
      <ProtectedRoute exact path='/notifications/:type/:id' render={() => <Notification />} />
      <ProtectedRoute exact path='/notifications' render={() => <Notifications />} />
      <ProtectedRoute exact path='/subscriptions' render={() => <Subscriptions />} />
      <ProtectedRoute exact path='/horoscopes' render={() => <Horoscopes />} />
      <ProtectedRoute exact path='/interpretations' render={() => <Interpretations />} />
    </Router>
  );
}

export default App;
