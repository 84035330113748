import { Dialog, Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import momentUtils from '@date-io/moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Loader from '../loader';
import React from 'react';
import { updateUserData } from '../../api/requests';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export default function UpdateModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(props.dob ? moment.utc(props.dob) : new Date());
  const params = useParams();
  
  React.useEffect(() => {
    setSelectedDate(moment.utc(props.dob));
  }, [props.dob]);
  
  const handleTimeChange = (f) => {
    setSelectedDate(f);
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const handleUpdate = async () => {
    const initDob = moment.utc(props.dob);
    const newDob = moment.utc(selectedDate);
    const sameDates = +initDob === +newDob;
    if (sameDates) return;

    setLoading(true);

    const dob = moment.utc(selectedDate).format('MM/DD/YYYY');
    const tob = moment.utc(selectedDate).format('hh:mm A');

    try {
      const response = await updateUserData({
        user_id: params.id,
        date_of_birth: dob,
        time_of_birth: tob
      });
      const valuesChanged = response.data?.success || false;
      setLoading(false);
      props.onClose(valuesChanged);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      { loading ? <Loader /> : null }
      <Wrapper>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <Grid container justify="space-around" direction='column'>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date of birth"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time of birth"
              value={selectedDate}
              onChange={handleTimeChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <ButtonWrapper>
          <Button variant='contained' color='primary' onClick={handleUpdate}>Update</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  )  
};
