import React from 'react';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import Loader from '../components/loader';
import HoroscopeSingle from '../components/horoscopes/horoscopeSingle';
import UpdateHoroscopeModal from '../components/modals/updateHoroscope';
import { getHoroscopes } from '../api/requests';

const HoroscopeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
`;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Horoscopes(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [horoscopes, setHoroscopes] = React.useState([]);
  const [currentHoroscope, setCurrentHoroscope] = React.useState(horoscopes[0]);
  const [modalOpened, setModalOpened] = React.useState(false);

  const fetchHoroscopes = async () => {
    setLoading(true);
    try {
      const response = await getHoroscopes();
      if (response.data?.horoscopes) {
        setHoroscopes(response.data.horoscopes);
      }
      // setHoroscopes([...dummy]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  React.useEffect(() => {
    (async () => {
      await fetchHoroscopes();
    })();
  }, []);
  
  const openModal = (item) => {
    const activeHoroscope = horoscopes.filter(horoscope => horoscope.sign === item);
    setCurrentHoroscope(...activeHoroscope);
    setModalOpened(true);
  };
  const closeModal = async (shouldUpdate) => {
    setModalOpened(false);
    if (shouldUpdate) {
      await fetchHoroscopes();
    }
  };
  

  return (
    <div className={classes.root}>
      <TopBar title='Oroscopes' />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { loading ? <Loader /> : null }
        <HoroscopeWrapper>
          {
            horoscopes.map((item, key) =>
              <HoroscopeSingle
                key={key}
                description={item.description}
                title={item.sign}
                onClick={() => openModal(item.sign)}
              />
            )
          }
        </HoroscopeWrapper>          

        <UpdateHoroscopeModal
          open={modalOpened}
          onClose={closeModal}
          horoscope={currentHoroscope}
        />
      </main>
    </div>
  );
}
