import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default function ProtectedRoute({ render: Component, ...rest }) {
  const isAuthenticated = localStorage.getItem('accessToken');
  const location = useLocation();
  
  React.useEffect(() => {}, [location]);
  
  return <Route { ...rest } render={(props) => (
    isAuthenticated
      ? <Component { ...props } />
      : <Redirect to={{ pathname: '/login' }} />
  )} />
}
