import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import AddNotificationModal from '../modals/addNotification';

const AddNotificationWrapper = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
`;

export default function NotificationList(props) {
  const history = useHistory();
  const params = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  
  const openModal = () => {
    props.onUpdate(false);
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);
  
  const handleClickRow = (event, notification) => {
    history.push(`/notifications/${params.type}/${notification._id}`, {
      notification: notification
    });
  };
  
  return (
    <>
      <AddNotificationWrapper>
        <Button variant='contained' color='primary' onClick={openModal}>Add</Button>
      </AddNotificationWrapper>
      <TableContainer component={Paper}>
        <Table >
          <TableBody>
            { props.notifications.map((notification, key) =>
              <TableRow key={key} hover style={{ cursor: 'pointer' }} onClick={(event) => handleClickRow(event, notification)}>
                <TableCell>{notification.title || notification.body}</TableCell>
              </TableRow>
            ) }
          </TableBody>
        </Table>
      </TableContainer>
      <AddNotificationModal open={modalOpen} onClose={closeModal} onUpdate={props.onUpdate} />
    </>
  )
}