import React from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';

const Container = styled.div`
  border: 1px solid #dde1f5;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
`;
const P = styled.p`
  padding: 0 10px;
  margin: 5px 0;
`;

export default function HoroscopeSingle(props) {
  return (
    <>
      <Container onClick={props.onClick}>
        <P style={{ textTransform: 'capitalize' }}>{props.title}</P>
        <Divider style={{backgroundColor: '#dde1f5' }} />
        <P>{props.description}</P>
      </Container>
    </>
  )
}