import React from 'react';
import styled from 'styled-components';
import { makeStyles, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import Loader from '../components/loader';
import AddSubscriptionModal from '../components/modals/addSubscription';
import { cancelSubscription, getSubscription } from '../api/requests';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubscriptionList = styled.div`
  margin-top: 70px;
  padding: 10px 0;
`;
const SubscriptionListItem = styled.div`
  border: 1px solid #f1f1f1;
  padding: 5px 10px;
  margin-bottom: 8px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Subscriptions(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(0);

  const [subscriptions, setSubscriptions] = React.useState([]);

  const fetchSubscriptions = async () => {
    setLoading(true);
    const response = await getSubscription();
    if (response.data.subscriptions) {
      setSubscriptions(response.data.subscriptions);
    }
    setLoading(false);
  };
  
  const onSubscriptionAdded = () => {
    setUpdate(update + 1);
  };

  React.useEffect(() => {
    (async () => {
      await fetchSubscriptions();
    })();
  }, [update]);

  const isSubscriptionActive = () => {
    if ( subscriptions[0]) {
      const startDate = new Date(subscriptions[0].startDate);
      const endDate = new Date(subscriptions[0].endDate);
      const startHasAlreadyBegun = startDate.getTime() < Date.now();
      const endDateIsInFuture = endDate.getTime() > Date.now();
      return endDateIsInFuture && startHasAlreadyBegun
    } else {
      return false;
    }
  };
  
  const lastSubscriptionMessage = () => {
    return isSubscriptionActive()
      ? `Subscription expires at ${new Date(subscriptions[0].endDate).toLocaleDateString()}`
      : 'No active subscription';
  };
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const cancelSub = async () => {
    setLoading(true);
    try {
      await cancelSubscription();
      setLoading(false);
      await fetchSubscriptions();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  return (
    <div className={classes.root}>
      <TopBar
        title='Subscriptions'
      />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { loading ? <Loader /> : null }
        <ButtonWrapper>
          <div style={{ display: 'flex'}}>
            <Alert severity='info'>{lastSubscriptionMessage()}</Alert>
            {
              isSubscriptionActive()
                ? <Button
                    color='secondary'
                    variant='contained'
                    onClick={cancelSub}
                    style={{ marginLeft: '20px'}}
                  >
                    Stop
                  </Button>
                : null
            }
          </div>
          <Button color='primary' variant='contained' onClick={openModal}>add</Button>
        </ButtonWrapper>
          
        <SubscriptionList>
          <SubscriptionListItem style={{ border: 'none', fontWeight: 600}}>
            <div>Start Date</div>
            <div>End Date</div>
          </SubscriptionListItem>
          {
            subscriptions.map(subscription => <SubscriptionListItem key={subscription._id}>
              <div>{new Date(subscription.startDate).toLocaleDateString()}</div>
              <div>{new Date(subscription.endDate).toLocaleDateString()}</div>  
            </SubscriptionListItem>)
          }
        </SubscriptionList>
      </main>
      <AddSubscriptionModal
        open={modalOpen}
        onClose={closeModal}
        onUpdate={onSubscriptionAdded}
      />
    </div>
  );
}

export default Subscriptions;