import React from 'react';
import styled from 'styled-components';
import TopBar from '../topBar';
import Sidebar from '../drawer';
import Loader from '../loader';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import { deleteNotification, updateNotification } from '../../api/requests';

const InfoBox = styled.div`
  padding: 12px;
`;
const TitleInput = styled.input`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 7px;
  padding: 10px;
  &:focus-visible {
    outline: none;
  }
`;
const BodyInput = styled.textarea`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  width: 100%;
  background: #ffffff;
  resize: none;
  border: 1px solid #ececec;
  border-radius: 7px;
  padding: 10px;
  height: 80px;
  &:focus-visible {
    outline: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 180px;
  margin-top: 20px;
`;
const Header = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
  padding: 5px 0;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Notification(props) {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [notification, setNotification] = React.useState({
    id: history.location.state.notification._id,
    title: history.location.state.notification.title,
    body: history.location.state.notification.body,
    category: history.location.state.notification.category,
  });
  const [notificationUpdate, setNotificationUpdate] = React.useState({
    id: history.location.state.notification._id,
    title: history.location.state.notification.title,
    body: history.location.state.notification.body,
    category: history.location.state.notification.category,
  });
  const [isEdit, setIsEdit] = React.useState(false);

  const setEdit = (value) => {
    setIsEdit(value);
  };
  
  const saveChanges = async () => {
    setLoading(true);
    setNotification({
      ...notification,
      title: notificationUpdate.title,
      body: notificationUpdate.body
    });
    try {
      await updateNotification({
        id: notification.id,
        title: notificationUpdate.title,
        body: notificationUpdate.body
      });
      setEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  const handleChange = (prop) => (event) => {
    setNotificationUpdate({ ...notificationUpdate, [prop]: event.target.value });
  };
  React.useEffect(() => {}, [notification]);
  const classes = useStyles();
  const params = useParams();
  
  const remove = async () => {
    setLoading(true);
    try {
      await deleteNotification(notification.id);
      setLoading(false);
      history.push(`/notifications/${params.type}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TopBar title='Notifications' backButton />
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          { loading ? <Loader /> : null }
          <div style={{ width: '400px' }}>
            <div>
              <Header>Title</Header>
              <div>
                {
                  isEdit ? <TitleInput value={notificationUpdate.title} onChange={handleChange('title')}/>
                  : <InfoBox>{notification.title}</InfoBox>
                }
              </div>
            </div>
            <div>
              <Header>Body</Header>
              <div>
                {
                  isEdit ? <BodyInput value={notificationUpdate.body} onChange={handleChange('body')}/>
                  : <InfoBox>{notification.body}</InfoBox>
                }
              </div>
            </div>
            <Buttons>
              { !isEdit ? <Button variant='contained' color='primary' onClick={() => setEdit(true)}>Change</Button> : null }
              { !isEdit ? <Button variant='contained' color='secondary' onClick={remove}>Delete</Button> : null }
              { isEdit ? <Button variant='contained' onClick={() => setEdit(false)}>Cancel</Button> : null }
              { isEdit ? <Button variant='contained' color='primary' onClick={saveChanges}>Save</Button> : null }
            </Buttons>
          </div>
        </main>
      </div>
    </>
  )
}
