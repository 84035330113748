import { Dialog, Button, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import moment from 'moment';
import momentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import React from 'react';
import { createSubscription } from '../../api/requests';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;
export default function AddSubscriptionModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    startDate: moment.utc(),
    endDate: moment.utc(),
  });

  const handleChange = (prop) => (value) => {
    setValues({
      ...values,
      [prop]: value
    });
  };
  
  const submit = async () => {
    try {
      setLoading(true);
      
      await createSubscription({
        startDate: values.startDate.toDate(),
        endDate: values.endDate.toDate(),
      });
      setLoading(false);
      props.onClose();
      props.onUpdate();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      { loading ? <Loader /> : null }
      <Wrapper>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <Grid container justify="space-around" direction='column'>
            <KeyboardDatePicker
              margin="normal"
              id="start-date"
              label="Start Date"
              format="MM/DD/YYYY"
              value={values.startDate}
              onChange={handleChange('startDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              margin="normal"
              id="end-date"
              label="End date"
              format="MM/DD/YYYY"
              value={values.endDate}
              onChange={handleChange('endDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <ButtonWrapper>
          <Button variant='contained' color='primary' onClick={submit}>Add</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  )
};
