import React from 'react';
import { makeStyles } from '@material-ui/core';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import ReportsList from '../components/reports/reportsList';
import Loader from '../components/loader';

import { getReports } from '../api/requests';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Reports(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [reports, setReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  
  const fetchReports = async () => {
    setLoading(true);
    try {
      const response = await getReports();
      setReports(response.data.reports);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  React.useEffect(() => { ( async() => { await fetchReports(); })() }, []);

  return (
    <div className={classes.root}>
      <TopBar title='Reports' />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { loading ? <Loader /> : null }
        <ReportsList reports={reports}  />
      </main>
    </div>
  );
}

export default Reports;