import { Dialog, Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import React, {useEffect} from 'react';
import {changePlanetDescription} from '../../api/requests';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export default function UpdatePlanetInterpretationModal({interpretation, onClose, isOpen}) {
    const [loading, setLoading] = React.useState(false);
    const [planetDescription, setPlanetDescription] = React.useState(interpretation?.description || '');

    useEffect(() => setPlanetDescription(interpretation?.description || ''), [isOpen]);

    const handleUpdate = async () => {
        setLoading(true);
        try {
            await changePlanetDescription({ planet: interpretation.planet, description: planetDescription});
            setLoading(false);
            onClose(true);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => onClose()}>
            { loading ? <Loader /> : null }
            <Wrapper>
                <div style={{ textTransform: 'capitalize', fontWeight: 600 }}>{interpretation.planet}</div>
                <TextField
                    label='PlanetDescription'
                    onChange={(e) => setPlanetDescription(e.target.value)}
                    placeholder={planetDescription}
                    value={planetDescription}
                    multiline
                    defaultValue={planetDescription}
                />


                <ButtonWrapper>
                    <Button variant='contained' color='primary' onClick={() => handleUpdate()}>Update</Button>
                </ButtonWrapper>
            </Wrapper>
        </Dialog>
    )
};
