import React from 'react';
import { makeStyles } from '@material-ui/core';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import Loader from '../components/loader';
import { getUsers } from '../api/requests';
import UserList from '../components/users/usersList';
import SendNotificationModal from '../components/modals/sendNotification';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Users(props) {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [sendNotificationOpened, setSendNotificationOpened] = React.useState(false);
  
  const fetchUsers = async () => {
    setLoading(true);
    const response = await getUsers();
    setUsers(response.data.users);
    setFilteredUsers(response.data.users);
    setLoading(false);
  };
  
  React.useEffect(() => {
    (async () => {
      await fetchUsers();
    })();
  }, []);
  
  const handleSearch = async (searchInput) => {
    const response = await getUsers(searchInput);
    setFilteredUsers(response.data.users);
  };
  
  const resetSearchFilter = () => {
    setFilteredUsers([...users]);
  };

  const openSendNotification = () => setSendNotificationOpened(true);
  const closeSendNotification = () => setSendNotificationOpened(false);
  
  return (
    <div className={classes.root}>
      <TopBar
        title='Users'
        withSearch
        onSearch={handleSearch}
        resetFilters={resetSearchFilter}
        sendNotification={openSendNotification}
      />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { loading ? <Loader /> : null }
        <UserList users={filteredUsers} />
        <SendNotificationModal
          open={sendNotificationOpened}
          onClose={closeSendNotification}
          forAll
        />
      </main>
    </div>
  );
}

export default Users;