import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import React from 'react';

export default function ReportsList(props) {
  const history = useHistory();

  const handleClickRow = (event, report) => {
    history.push(`/reports/${report.id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: '600' }} >By</TableCell>
            <TableCell style={{ fontWeight: '600' }} >To</TableCell>
            <TableCell style={{ fontWeight: '600' }} >Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { props.reports.map((report, key) =>
            <TableRow key={key} hover style={{ cursor: 'pointer' }}>
              <TableCell><Link style={{ textDecoration: 'none' }} to={`/users/${report.sender}`}>{report.sender}</Link></TableCell>
              <TableCell><Link style={{ textDecoration: 'none' }} to={`/users/${report.target}`}>{report.target}</Link></TableCell>
              <TableCell>{report.kind}</TableCell>
            </TableRow>
          ) }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
