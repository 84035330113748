import React from 'react';
import {Button, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core';
import styled from 'styled-components';
import Sidebar from '../components/drawer';
import TopBar from '../components/topBar';
import Loader from '../components/loader';
import HoroscopeSingle from '../components/horoscopes/horoscopeSingle';
import {getInterpretations} from "../api/requests";
import UpdateZodiacInterpretationModal from "../components/modals/updateZodiacInterpretation";
import UpdatePlanetInterpretationModal from "../components/modals/updatePlanetInterpretation";

const planets = ['sun', 'moon', 'mercury', 'venus', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune', 'pluto', 'rising', 'descendant', 'mid_heaven', 'ic', 'north_node', 'south_node', 'chiron'];

const InterpretationsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
`;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function Interpretations() {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [currentPlanet, setCurrentPlanet] = React.useState(planets[0])
    const [currentZodiac, setCurrentZodiac] = React.useState('');

    const [interpretation, setInterpretation] = React.useState({planet: '', description: '', zodiacs: {}});

    const [isZodiacModalOpened, setIsZodiacModalOpened] = React.useState(false);
    const [isPlanetModalOpened, setIsPlanetModalOpened] = React.useState(false);


    const fetchInterpretation = async () => {
        setLoading(true);
        try {
            const response = await getInterpretations({planet: currentPlanet});
            if (response.data) {
                setInterpretation(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        (async () => {
            await fetchInterpretation();
        })();
    }, [currentPlanet]);

    const openZodiacModal = (item) => {
        setCurrentZodiac(item);
        setIsZodiacModalOpened(true);
    };
    const closeZodiacModal = async (shouldUpdate) => {
        setIsZodiacModalOpened(false);
        if (shouldUpdate) {
            await fetchInterpretation();
        }
    };

    const openPlanetModal = () => {
        setIsPlanetModalOpened(true);
    };
    const closePlanetModal = async (shouldUpdate) => {
        setIsPlanetModalOpened(false);
        if (shouldUpdate) {
            await fetchInterpretation();
        }
    };


    return (
        <div className={classes.root}>
            <TopBar title='Interpretations' />
            <Sidebar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <InputLabel id="planet-select-label">Planet</InputLabel>
                <Select
                    labelId="planet-select-label"
                    id="planet-select"
                    value={currentPlanet}
                    label="Planet"
                    onChange={(e) => setCurrentPlanet(e.target.value)}
                >
                    {planets.map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>

                <h1>{interpretation?.planet.toUpperCase()}</h1>
                <div>{interpretation?.description}</div>
                <Button variant='contained' color='primary' onClick={() => openPlanetModal()}>Change description</Button>
                { loading ? <Loader /> : null }
                <InterpretationsWrapper>
                    {
                        Object.entries(interpretation?.zodiacs).map(([zodiac, description], key) =>
                            <HoroscopeSingle
                                key={key}
                                description={description}
                                title={zodiac}
                                onClick={() => openZodiacModal(zodiac)}
                            />
                        )
                    }
                </InterpretationsWrapper>

                <UpdateZodiacInterpretationModal
                    isOpen={isZodiacModalOpened}
                    onClose={() => closeZodiacModal(true)}
                    interpretation={interpretation}
                    zodiac={currentZodiac}
                />

                <UpdatePlanetInterpretationModal
                    isOpen={isPlanetModalOpened}
                    onClose={() => closePlanetModal(true)}
                    interpretation={interpretation}
                />
            </main>
        </div>
    );
}
