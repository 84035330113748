import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const Deleted = styled.div`
  color: #d2d2d2;
`;

export default function UserList(props) {
  const history = useHistory();
  
  const handleClickRow = (event, user) => {
    history.push(`/users/${user.id}`);
  };
  
  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: '600' }} >Photo</TableCell>
            <TableCell style={{ fontWeight: '600' }} >Name</TableCell>
            <TableCell style={{ fontWeight: '600' }} >Phone Number</TableCell>
            <TableCell style={{ fontWeight: '600' }} >ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { props.users.map((user, key) =>
            <TableRow key={key} hover style={{ cursor: 'pointer' }} onClick={(event) => handleClickRow(event, user)}>
              <TableCell>
                {
                  user.profilePhoto
                    ? <div style={{ borderRadius: '50%', width: '50px', height: '50px', overflow: 'hidden', display: 'flex' }}>
                        <img style={{ width: '100%', objectFit: 'cover' }} src={user.profilePhoto?.path} alt=""/>
                      </div>
                    : null
                }
              </TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>
                {user.phoneNumber
                  ? user.phoneNumber
                  : user.deletedAt
                    ? <Deleted>deleted at <span style={{color: '#ababab'}}>{new Date(user.deletedAt).toLocaleDateString()}</span></Deleted>
                    : null
                }
              </TableCell>
              <TableCell>{user.id}</TableCell>
            </TableRow>  
          ) }
        </TableBody>
      </Table>
    </TableContainer>
  )  
}
