import { Dialog, Button, TextField, Select, MenuItem, InputLabel, makeStyles, FormControl } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../loader';
import React from 'react';
import { createNotification } from '../../api/requests';

const Wrapper = styled.div`
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function AddNotificationModal(props) {
  // const params = useParams();
  const classes = useStyles();
  
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    title: '',
    body: '',
    category: ''
  });
  
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };

  const handleAdd = async () => {
    setLoading(true);
    try {
      await createNotification({
        title: values.title,
        body: values.body,
        category: values.category
      });
      setLoading(false);
      setValues({
        title: '',
        body: '',
        category: '',
      });
      props.onUpdate(true);
      props.onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      { loading ? <Loader /> : null }
      <Wrapper>
        <TextField label='Title' onChange={handleChange('title')}/>
        <TextField label='Body' multiline rows={4} onChange={handleChange('body')}/>
        <FormControl className={classes.formControl}>
          <InputLabel id="category-2">Category</InputLabel>
          <Select value={values.category} onChange={handleChange('category')} labelId="category-2">
            <MenuItem value={'NEW_MATCH'}>New Match</MenuItem>
            <MenuItem value={'NEW_MESSAGE'}>New Message</MenuItem>
            <MenuItem value={'EXPIRING_MATCH'}>Expiring Match</MenuItem>
            <MenuItem value={'MERCURY_RETROGRADE_ACTIVE'}>Active Mercury Retrograde</MenuItem>
            <MenuItem value={'MERCURY_RETROGRADE_BEFORE'}>Before Mercury Retrograde</MenuItem>
            <MenuItem value={'MERCURY_RETROGRADE_AFTER'}>After Mercury Retrograde</MenuItem>
            <MenuItem value={'SOUL_CARE'}>Soul Care</MenuItem>
            <MenuItem value={'DAILY_PROSPECTS'}>Daily Prospects</MenuItem>
          </Select>  
        </FormControl>
        <ButtonWrapper>
          <Button variant='contained' color='primary' onClick={handleAdd}>Add</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  )
};
