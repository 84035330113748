import React from 'react';
import { Container } from '@material-ui/core';
import Loader from '../components/loader';
import { useHistory } from 'react-router-dom';
import LoginComponent from '../components/auth/login';
import { login } from '../api/requests';
import { isNil } from 'lodash';

export default function AuthContainer(props) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!isNil(token)) {
      history.push('/users');
    }
  }, []);
  
  const handleLogin = async (values) => {
    setError(null);
    setLoading(true);
    try {
      const response = await login(values.login, values.password);
      if (response?.data?.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);        
        setLoading(false);
        history.push('/users');
      }
    } catch (error) {
      setLoading(false);
      const { errorMessage } = error.response.data;
      if (errorMessage) {
        setError(errorMessage);
      }
    }
  };
  
  return (
    <Container>
      { loading ? <Loader /> : null}
      <LoginComponent login={handleLogin} error={error} />
    </Container>
  )
};
