import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const LoaderWrapper = styled.div`
  position: fixed;
  top: -25px;
  left: -25px;
  width: calc(100% + 49px);
  height: calc(100% + 49px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .1);
`;

export default function Loader(props) {
  return (
    <LoaderWrapper>
      <CircularProgress color='secondary' />
    </LoaderWrapper>
  )
};
