import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .2);
  padding: 30px 40px;
  & > div {
    margin: 5px 0;
  }
  button {
    margin-top: 20px;
  }
`; 

const Error = styled.span`
  color: #ea2464;
  height: 20px;
`;

export default function LoginComponent(props) {
  const [values, setValues] = useState({
    login: '',
    password: ''
  });
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };
  const handleSubmit = async () => {
    await props.login(values);
  };
  return (
    <Wrapper>
      <FormContainer>
        <TextField label='Username' variant='outlined' size='small' value={values.login} onChange={handleChange('login')} />
        <TextField type='password' variant='outlined' size='small' label='Password' value={values.password} onChange={handleChange('password')} />
        <Error>{props.error}</Error>
        <Button color='primary' variant='contained' onClick={handleSubmit}>Login</Button>
      </FormContainer>  
    </Wrapper>
  )
};
