import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Hidden,
  Drawer,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ReportIcon from '@material-ui/icons/Report';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarsIcon from '@material-ui/icons/Stars';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import React from 'react';
import { Link } from 'react-router-dom';
import {MenuBook} from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  nested: {
    paddingLeft: theme.spacing(5),
    textDecoration: 'none',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Sidebar(props) {
  const { window } = props;
  // const theme = useTheme();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List style={{ padding: 0 }}>
        <Link to='/users' style={{ textDecoration: 'none', color: '#3f51b5' }}>
          <ListItem button>
            <ListItemIcon><PeopleIcon color='primary' /></ListItemIcon>
            <ListItemText primary='Users' />
          </ListItem>
        </Link>
        <Divider />
        <Link to='/subscriptions' style={{ textDecoration: 'none', color: '#3f51b5' }}>
          <ListItem button>
            <ListItemIcon><StarsIcon color='primary' /></ListItemIcon>
            <ListItemText primary='Subscriptions' />
          </ListItem>
        </Link>
        <Divider />
        <Link to='/horoscopes' style={{ textDecoration: 'none', color: '#3f51b5' }}>
          <ListItem button>
            <ListItemIcon><MovieFilterIcon color='primary' /></ListItemIcon>
            <ListItemText primary='Oroscopes' />
          </ListItem>
        </Link>
        <Divider />
        <Link to='/interpretations' style={{ textDecoration: 'none', color: '#3f51b5' }}>
          <ListItem button>
            <ListItemIcon><MenuBook color='primary' /></ListItemIcon>
            <ListItemText primary='Interpretations' />
          </ListItem>
        </Link>
        <Divider />
        <Link to='/reports' style={{ textDecoration: 'none', color: '#3f51b5' }}>
          <ListItem button>
            <ListItemIcon><ReportIcon color='secondary' /></ListItemIcon>
            <ListItemText primary='Reports' />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List style={{ padding: 0 }}>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <NotificationsIcon color='primary'/>
          </ListItemIcon>
          <ListItemText primary="Notifications" style={{ color: '#3f51b5' }} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to='/notifications/messages' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Messages" />
              </ListItem>
            </Link>
            <Link to='/notifications/new_matches' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="New Matches" />
              </ListItem>
            </Link>
            <Link to='/notifications/expiring_matches' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Expiring Matches" />
              </ListItem>
            </Link>
            <Link to='/notifications/mercury_active' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Mercury Retrograde Active" />
              </ListItem>
            </Link>
            <Link to='/notifications/mercury_before' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Before Mercury Retrograde" />
              </ListItem>
            </Link>
            <Link to='/notifications/mercury_after' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="After Mercury Retrograde" />
              </ListItem>
            </Link>
            <Link to='/notifications/soul_care' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Soul Care" />
              </ListItem>
            </Link>
            <Link to='/notifications/daily_prospects' style={{ textDecoration: 'none', color: '#3f51b5' }}>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Daily Prospects" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor='left'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};
