import {
  AppBar,
  CssBaseline,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

const BackButton = styled.div`
  cursor: pointer;
`;
const SearchWrapper = styled.div`
  .MuiInputBase-input, .MuiSvgIcon-root {
    color: #e0e0e0;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #e0e0e0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #e0e0e0;
  }
`;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function TopBar(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const history = useHistory();
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleBackClick = () => {
    history.goBack();
  };
  
  const handleInputSearch = (event) => {
    setSearchInput(event.target.value);
  };
  
  React.useEffect(() => {
    if (!searchInput && props.withSearch) {
      props.resetFilters();
    }
  }, [searchInput]);
  
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            { props.backButton
                ? <BackButton onClick={handleBackClick}>← {props.title}</BackButton>
                : props.title
            }
          </Typography>
          { props.withSearch ?
            <SearchWrapper>
              <TextField
                color='secondary'
                value={searchInput}
                onChange={handleInputSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant='contained'
                size='small'
                color='secondary'
                style={{ marginLeft: 10, top: 1 }}
                onClick={() => props.onSearch(searchInput)}
              >
                Search
              </Button>
            </SearchWrapper>
            : null
          }
          {
            props.sendNotification ?
              <Button
                color='secondary'
                size='small'
                variant='contained'
                style={{ marginLeft: '10px'}}
                onClick={props.sendNotification}
              >
                Send notification
              </Button>
              : null
          }
        </Toolbar>
      </AppBar>
    </>  
  );
}
